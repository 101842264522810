<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="800px" @close="close">
    <vab-query-form style="padding-top: 20px">
      <vab-query-form-left-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="refresh($event)">
          刷新
        </el-button>
      </vab-query-form-left-panel>
      <!-- <vab-query-form-right-panel :span="12">
        <el-form style="margin-top: 20px">
          <el-form-item label="选择展会">
            <el-select
              v-model="meetingCode"
              class="m-2"
              placeholder="请选择展会"
              size="large"
              @change="showResult"
            >
              <el-option
                v-for="item in exhList"
                :key="item.meetingCode"
                :label="item.meetingName"
                :value="item.meetingCode"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel> -->
    </vab-query-form>

    <el-table border :data="list">
      <el-table-column label="批次号" prop="batchNo" show-overflow-tooltip />
      <el-table-column label="渠道名称" prop="channelName" show-overflow-tooltip />
      <el-table-column label="文件名称" prop="fileName" show-overflow-tooltip />
      <el-table-column label="导入时间" prop="createTime" show-overflow-tooltip />
      <el-table-column label="失败数量" prop="failCount" show-overflow-tooltip />
      <el-table-column label="成功数量" prop="successCount" show-overflow-tooltip />

      <el-table-column label="操作" show-overflow-tooltip width="150">
        <template #default="{ row }">
          <el-button type="success" @click="_export(row, $event)">
            下载
          </el-button>
          <!-- <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button> -->
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  defineAsyncComponent,
  reactive,
  toRefs,
  onMounted,
} from 'vue'
import {
  doEdit,
  doAdd,
  downTemplate,
  _importVisitor,
  _importGetExhInfo,
  _importChannelExportErrorData,
  _importChannelImportErrorDataList,
} from '@/api/channel'
import { useStore } from 'vuex'
// 引入下拉数选择
export default defineComponent({
  name: 'UserManagementEdit',
  components: {},
  props: {
    eventId: {
      type: String,
      default: '',
    },
  },
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    // 自定义手机验证

    const state = reactive({
      formRef: null,
      form: {},
      identityType: '',
      file: null,
      departmentData: [],
      rules: {
        channelName: [
          { required: true, trigger: 'blur', message: '请输入渠道名称' },
        ],
        remarks: [
          { required: true, trigger: 'blur', message: '请输入渠道备注' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      roleList: [],
      exhList: [],
      identityList: [],
      list: [],
      meetingCode: '',
    })

    const showEdit = async (row) => {
      state.title = '导入结果查询'
      state.form = Object.assign({}, row)
      state.meetingCode = row.meetingCode
      state.list = []
      fetchMeetData(row.type)
      state.dialogFormVisible = true
      showResult()
    }

    const showResult = () => {
      _importChannelImportErrorDataList({
        meetingCode: state.meetingCode,
      }).then((res) => {
        console.log(res)
        state.list = res.data
      })
    }

    const refresh = () => {
      showResult()
    }

    const _export = (row) => {
      _importChannelExportErrorData({
        batchNo: row.batchNo,
      }).then((res) => {
        console.log(res)
        window.open(res.data.fileUrl)
      })
    }

    const fetchMeetData = (meetingType) => {
      _importGetExhInfo(meetingType).then((res) => {
        state.exhList = res.data
      })
    }

    const downLoadTemplate = (e) => {
      console.log(e)
      downTemplate().then((res) => {
        console.log(res)
        window.open(res.data)
      })
    }

    const changeFile = (val) => {
      console.log(val)
      // console.log("选择文件成功");
      let nameArray = val.name.split('.')
      if (nameArray[nameArray.length - 1] === 'xlsx') {
        // file保存选择的文件
        state.file = val.raw
      } else {
        this.$message({
          message: '表格格式错误,请确认表格内容并重新上传',
          type: 'warning',
          duration: 2000,
        })
      }
    }
    const close = () => {
      state.form = {}
      state.dialogFormVisible = false
    }
    const save = () => {
      let formData = new FormData()
      formData.append('file', state.file)
      const { appKey, channelNo, meetingCode } = state.form
      _importVisitor(
        formData,
        appKey,
        channelNo,
        'EX1520295848969838592',
        state.identityType
      ).then((res) => {
        console.log(res)
        // 导入失败
        if (!res.data.success) {
          proxy.$baseMessage(
            '导入失败，请在下载中的Excel文件中查看原因',
            'error',
            'vab-hey-message-error'
          )
          window.open(res.data.fileUrl)
        } else {
          proxy.$baseMessage('导入成功', 'success', 'vab-hey-message-success')
          state.dialogFormVisible = false
        }
      })
    }

    return {
      ...toRefs(state),
      showEdit,
      changeFile,
      fetchMeetData,
      downLoadTemplate,
      close,
      showResult,
      refresh,
      _export,
      save,
    }
  },
})
</script>

<style lang="scss" scoped>
.flexBox {
  display: flex;
  align-content: center;
  justify-content: right;
}
</style>
